<template>
	<div class="page">
		<div class="title">
			<p>{{lang==6? list[type-1].cn.title:list[type-1].en.title}}</p>
			<p class="yellowLine"></p>
		</div>
		<div class="content">
			<div class="top">
				<div class="text" v-html="lang==6? list[type-1].cn.con:list[type-1].en.con">
				</div>
				<img :src="require('../../assets/sustainability/img'+type+'.png')" />
				<p class="black" @click="$router.go(-1)">
					< {{lang==6?'返回':'Back'}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {
				type: 1,
				list: [{
					cn: {
						title: '为客户',
						con: '集团的主要目标是提供高质量的产品和服务给客户，保持业务持续增长。我们通过一系列数字化的举措，打造无限客户旅程和尊享客户体验，提供超越客户期待的可持续移动出行解决方案。基于可靠性和专业精神，我们同客户建立长期合作关系。'
					},
					en: {
						title: 'FOR CUSTOMERS',
						con: "The main objectives for the group are to provide high-quality products and services to the customers and to sustain the continuous business growth. Through a series of digital initiatives, seamless customer journey and exclusive customer experience are created. Moreover, we provide sustainable mobility solutions beyond customers' expectations and aim to retain the long-term relationship with our customers based on reliability and professionalism. "
					}
				}, {
					cn: {
						title: '为员工',
						con: '企业的成功和可持续发展离不开全体员工的努力和付出。我们持续营造一个更安全健康，更具协作性和包容性的工作环境，并提供资源和平台支持，为员工赋能，帮助员工充分发挥潜力。此外，我们还通过多样的活动，给与员工感谢，激励，并创造工作之余的乐趣。'
					},
					en: {
						title: 'FOR EMPLOYEES',
						con: 'The success and sustainable development of a company cannot be separated from the efforts and dedication of all employees. We continue to cultivate a safer and healthier, more collaborative, and inclusive workplace. By providing support on the resource and platforms, we empower our employees to develop their full potential. In addition, various staff caring activities are carried out, to inspire, to appreciate and to create fun! '
					}
				}, {
					cn: {
						title: '为社会',
						con: '在明确的目标和承诺下，我们坚守商业伦理道德，保护环境和资源，同时支持社会的建设与繁荣。宝爱捷（中国）推广的一系列“聚心同行”企业社会责任活动，包括“儿童安全交通讲堂”、“爱心图书角”、“公益助农” 、以及公益捐赠等项目。'
					},
					en: {
						title: 'FOR SOCIALITY ',
						con: 'With clear objectives and commitments, we adhere to the business ethics, conserve resources and environment, and contribute to the development and prosperity of local sociality. Under the "TOGETHER" program, PAIG (China) has implemented a series of corporate social responsibility activities, including "Traffic safety lectures for kids", "TOGETHER Library", "Festival purchasing aid for agriculture", "Contribution in kind", etc. '
					}
				}, {
					cn: {
						title: '为环境',
						con: `大众集团，作为全球领先的、为数以千万计的人提供出行服务的服务提供商，致力于为我们和未来几代人提供可持续的智慧出行服务并成为环境保护领域的典范。宝爱捷中国作为大众集团和保时捷控股的一部分，我们对可持续发展的承诺请见 <a href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/%E5%AE%9D%E7%88%B1%E6%8D%B7%E5%8F%AF%E6%8C%81%E7%BB%AD%E5%8F%91%E5%B1%95%E5%A3%B0%E6%98%8E.pdf" target="_blank">《宝爱捷可持续发展声明》</a>，我们亦会在<a href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/%E7%8E%AF%E4%BF%9D%E4%BD%BF%E5%91%BD%E5%AE%A3%E8%A8%80.pdf" target="_blank">《环保使命宣言》</a>的指引下，遵守<a href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/%E9%9B%86%E5%9B%A2%E7%8E%AF%E5%A2%83%E6%94%BF%E7%AD%96%E5%A3%B0%E6%98%8E.pdf" target="_blank">《集团环境政策声明》</a>和大众集团发布的<a href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/%E5%85%A8%E6%96%B0%E4%BC%81%E4%B8%9A%E7%8E%AF%E4%BF%9D%E5%AE%97%E6%97%A8.pdf" target="_blank">《全新企业环保宗旨》</a>，努力减少对环境的影响，成为环保领域的榜样。`
					},
					en: {
						title: 'FOR ENVIRONMENT',
						con: `Volkswagen Group, a global leader who has always made individual and affordable mobility possible for millions of people, is aiming to be a model company in the field of the environment and pursuing to make mobility sustainable for us and for the future generations. PAIG China as a part of Volkswagen Group and Porsche Holding, <a href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/PAIG%20Sustainability%20Statement.pdf" target="_blank">PAIG Sustainability Statement</a> details our commitment towards sustainability. guided by <a href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/mission_statement_environment.pdf" target="_blank">Mission Statement Environment</a>, we also commit to comply with <a href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/group_environmental_policy_statement.pdf" target="_blank">Group Environmental Policy Statement</a> and <a href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/new_corporate_mission_statement_environment.pdf" target="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/new_corporate_mission_statement_environment.pdf">New Corporate Mission Statement</a> Environment published by Volkswagen Group, to minimize the impact on the environment and to become an environmental role model. `
					}
				}]
			}
		},
		mounted() {
			this.type = this.$route.query.type ? this.$route.query.type : 1
		},
		methods: {
			getInfo() {

			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding: 170px 0 70px;
		font-size: 18px;
	}

	.title {
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 40px;
		font-weight: bold;
		color: #FFF;
		text-transform: uppercase;
		padding: 0 111px;
	}

	.b {
		font-weight: bold;
		font-size: 24px;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	ul {
		margin-left: 15px;
	}

	.content {
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 20px;
		font-weight: 350;
		line-height: 36px;
		line-height: 46px;
		width: 100%;

		.top {
			margin: 0 60px;
			background-color: #EEEEEE;
			border-radius: 16px;
			padding: 56px 51px;

			.text {
				margin-bottom: 20px;
			}

			img {
				width: 100%;
				margin: 20px 0;
			}
		}
	}

	/deep/ a {
		color: #97233F;
		text-decoration: none;
	}

	.black {
		float: right;
		font-size: 24px;
		font-weight: bold;
		cursor: pointer;
	}
</style>